import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import SearchBox from "./SearchBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

export default function PlacesSearch({ handleSelect }) {
  const [address, setAddress] = useState(``);

  const handleChange = (address) => {
    setAddress(address);
    handleSelect(address);
  };

  return (
    <SearchBox
      placeholder="Search a location"
      value={address}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
}

import React, { useMemo, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/pageHeader";
import { graphql } from "gatsby";
import PlacesSearch from "../components/PlacesSearch";
import ListingList from "../components/ListingList";
import ListingContainer from "../components/ListingContainer";
import ListBusinessButton from "../components/ListBusinessButton";

function IndexPage({ data }) {
  const [searchedAddress, setSearchedAddress] = React.useState("");
  const listings = useMemo(
    () => data.allBusinessListing.edges.map(({ node }) => node),
    [data]
  );

  const handleSelect = (address) => {
    setSearchedAddress(address);
  };

  return (
    <Layout>
      <SEO
        keywords={[`find local fruit box`, `find local vegetable box`]}
        title="Vegeboxes"
        description="Find local fruit and vegetable box delivery and pick-up."
      />
      <PageHeader>
        Find local <span className="text-green-600">fruit and vegetable</span>{" "}
        box delivery and pick-up
      </PageHeader>
      <div className="flex justify-center mt-16">
        <ListBusinessButton />
      </div>

      <ListingContainer>
        <div className="px-6">
          <nav className="flex items-stretch">
            <PlacesSearch
              handleSelect={handleSelect}
              placeholder="Search by location..."
            />
          </nav>
          <hr
            className="border-gray-100 mt-3 mb-3"
            style={{ borderTopWidth: `3px` }}
          />
        </div>
        <ListingList
          listings={listings}
          filter={(listing) => {
            return listing.deliveryLocations.some(({ name }) =>
              name.toLowerCase().includes(searchedAddress.toLowerCase())
            );
          }}
        />
      </ListingContainer>
    </Layout>
  );
}

export const query = graphql`
  fragment businessListingDetails on BusinessListing {
    id
    name
    description
    email
    deliveryLocations {
      name
      state
    }
    social {
      facebook
      instagram
      twitter
    }
    website
  }

  query {
    allBusinessListing {
      edges {
        node {
          ...businessListingDetails
        }
      }
      totalCount
    }
  }
`;

export default IndexPage;

import React from "react";
import searchSvg from "../images/search.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function SearchBox({ isLoading, ...props }) {
  return (
    <div
      className="flex items-center relative mr-auto text-gray-800 flex-grow w-full border-gray-400 border rounded px-2 hover:shadow transition-all transition-250 transition-ease"
      style={{ height: `3.25rem` }}
    >
      {!isLoading && <img src={searchSvg} alt="Search Icon" />}
      {isLoading && <CircularProgress size={24} />}
      <input
        {...props}
        autoComplete="off"
        className="flex-grow bg-white text-base focus:outline-none ml-2 placeholder-gray-700"
        aria-label="Location search"
      />
    </div>
  );
}
